import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useTable } from 'react-table'
import { useStores } from '../../../stores/store'
import { Link } from 'react-router-dom';
import { Container } from '../../common/Container'
import HashLoader from 'react-spinners/HashLoader';
import { FaEdit } from 'react-icons/fa';

function Table({ columns, data }) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  return (
    <div className="w-full mt-2 flex flex-col shadow-2xl">
      <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-primary sm:rounded-lg">
            <table {...getTableProps()} className="min-w-full divide-y divide-primary bg-white">
              <thead className="bg-gray-50">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              
              <tbody className="bg-white divide-y divide-primary" {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                    return (
                      <tr className="hover:bg-primary-light" {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return <td className="px-6 py-4 whitespace-nowrap" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                      </tr>
                    )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

function SchoolUsersPage() {
  const { auth } = useStores();
  const usersData = useQuery({
    queryKey: [],
    queryFn: () => auth.getUsersOfSchool(auth.school.id).then(res => res.data),
  });
    
  const columns = React.useMemo(
      () => [
      {
          Header: "Utenti",
          columns: [
            {
              Header: "Modifica",
              accessor: "id",
              Cell: ({ cell }) => (
                <Link reloadDocument to={
                    `/school/dashboard/users/${cell.row.values.id}`}>
                  <FaEdit />
                </Link>
              )
            },
            {
                Header: 'Nome',
                accessor: 'name',
            },
            {
                Header: 'Cognome',
                accessor: 'surname',
            },
            {
                Header: 'Scuola',
                accessor: 'fk_school_name',
            },
          ],
      },
      {
        Header: "Login",
        columns: [
        {
            Header: 'E-mail',
            accessor: 'email',
        },
        {
          Header: "Attivo",
          accessor: "is_old",
          Cell: ({ cell }) => (
            cell.row.values.is_old ? "No" : "Si"
          )
        },
        ],
    },
    ],
      []
  );

  if (usersData == null || usersData.isLoading || usersData.data == null)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  return (
    <Container>
      <div className="flex flex-col gap-6 justify-start items-start">
        <Link reloadDocument className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" to="/school/dashboard/users/new">Aggiungi utente</Link>
        <Table columns={columns} data={usersData.data} />
      </div>
    </Container>
  )
}

export default SchoolUsersPage
