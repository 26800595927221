import React from 'react';
import { config } from './config';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

//Style
import 'tailwindcss/tailwind.css';
import './index.css';

//Stores
import { Stores, StoresContext } from './stores/contexts';

//Pages
import LoginPage from './pages/loginPage';
import {Helmet} from "react-helmet";

// Translation
import './i18n/i18n.ts';

//Routing
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import { AuthStore } from './stores/netStore';
import SubjectSelectionPage from './pages/user/subjectSelectionPage';
import ClassSelectionPage from './pages/user/classSelectionPage';
import LessonSelectionPage from './pages/user/lessonSelectionPage';
import LessonGroupSelectionPage from './pages/user/lessonGroupSelectionPage';
import InteractiveLessonPage from './pages/user/lessons/interactiveLessonPage';
import SignupPage from './pages/signupPage';
import AdminCodesPage from './pages/admin/codes/codesPage';
import AdminLessonGroupsPage from './pages/admin/lessons/lessonGroupsPage';
import AdminNewLessonGroupPage from './pages/admin/lessons/newGroup/newGroupPage';
import AdminNewLessonComposerPage from './pages/admin/lessons/newLesson/newLessonComposerPage';
import AdminLessonsPage from './pages/admin/lessons/lessonsPage';
import AdminSchoolsPage from './pages/admin/schools/schoolsPage';
import AdminSchoolEditPage from './pages/admin/schools/schoolEditPage';
import AdminSchoolLessonGroupsPage from './pages/admin/schools/schoolLessonGroupsPage';
import AdminUsersPage from './pages/admin/users/usersPage';
import SchoolLessonGroupsPage from './pages/school/lessons/lessonGroupsPage';
import SchoolLessonsPage from './pages/school/lessons/lessonsPage';
import SchoolUsersPage from './pages/school/users/usersPage';
import SchoolUserEditPage from './pages/school/users/userEditPage';
import AdminUserEditPage from './pages/admin/users/userEditPage';
import MediaSyncPage from './pages/mediaSyncPage';
import AdminCodeEditPage from './pages/admin/codes/codeEditPage';
import StartupPage from './pages/startupPage';
import PasswordRecovery1Page from './pages/passwordRecovery1Page';
import PasswordRecovery2Page from './pages/passwordRecovery2Page';
import SchoolTypeSelectionPage from './pages/user/schoolTypeSelectionPage';
import BettLessonGroupSelectionPage from './pages/user/bettLessonGroupSelectionPage';
import BettLessonSelectionPage from './pages/user/bettLessonSelectionPage';

const authStore = new AuthStore();
let stores: Stores = {
	auth: authStore,
};

if (config.env === 'production') {
	console.log = function no_console() { };
}

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: true,
		},
	},
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<StoresContext.Provider value={stores}>
			<Helmet>
				<title>{config.isMiri() ? "Aula MIRI" : "Tavolo Pinguin"}</title>
				<link rel="icon" href={config.isMiri() ? "favicon_miri.ico" : "favicon_pinguin.ico"} />
			</Helmet>
			<QueryClientProvider client={queryClient}>
				<Router>
					<Routes>
						{/* Public routes */}
						<Route path='/startup/' element={<StartupPage />} />
						<Route path='/login/' element={<LoginPage />} />
						<Route path='/signup/' element={<SignupPage />} />
						<Route path='/password-recovery/' element={<PasswordRecovery1Page />} />
						<Route path='/password-reset/' element={<PasswordRecovery2Page />} />

						<Route path='/media-sync/' element={<MediaSyncPage />} />

						{/* User routes */}
						<Route path='/user/school-type-selection/' element={<SchoolTypeSelectionPage />} />
						<Route path='/user/subject-selection/' element={<SubjectSelectionPage />} />
						<Route path='/user/class-selection/' element={<ClassSelectionPage />} />
						<Route path='/user/lesson-group-selection/' element={<LessonGroupSelectionPage />} />
						<Route path='/user/lesson-selection/' element={<LessonSelectionPage />} />
						<Route path='/user/bett-lesson-group-selection/' element={<BettLessonGroupSelectionPage />} />
						<Route path='/user/bett-lesson-selection/' element={<BettLessonSelectionPage />} />
						<Route path='/user/interactive-lesson/' element={<InteractiveLessonPage />} />

						{/* School routes */}
						<Route path='/school/dashboard/' element={<Navigate to='/school/dashboard/lessons' />} />
						<Route path='/school/dashboard/users/' element={<SchoolUsersPage />} />
						<Route path='/school/dashboard/users/:id' element={<SchoolUserEditPage />} />

						<Route path='/school/dashboard/lessons' element={<SchoolLessonsPage />} />
						<Route path='/school/dashboard/lesson-groups' element={<SchoolLessonGroupsPage />} />

						{/* Admin routes */}
						<Route path='/admin/dashboard/' element={<Navigate to='/admin/dashboard/schools' />} />
						<Route path='/admin/dashboard/schools/' element={<AdminSchoolsPage />} />
						<Route path='/admin/dashboard/schools/:id' element={<AdminSchoolEditPage />} />
						<Route path='/admin/dashboard/schools/:id/lessons/' element={<AdminSchoolLessonGroupsPage />} />
						<Route path='/admin/dashboard/users/' element={<AdminUsersPage />} />
						<Route path='/admin/dashboard/users/:id' element={<AdminUserEditPage />} />

						<Route path='/admin/dashboard/lessons' element={<AdminLessonsPage />} />
						<Route path='/admin/dashboard/lessons/:id' element={<AdminNewLessonComposerPage />} />

						<Route path='/admin/dashboard/lesson-groups' element={<AdminLessonGroupsPage />} />
						<Route path='/admin/dashboard/lesson-groups/:id' element={<AdminNewLessonGroupPage />} />

						<Route path='/admin/dashboard/codes/' element={<AdminCodesPage />} />
						<Route path='/admin/dashboard/codes/:id' element={<AdminCodeEditPage />} />

						{/* Fallback route */}
						<Route path='*' element={<Navigate to='/login' />} />
					</Routes>
				</Router>
			</QueryClientProvider>
		</StoresContext.Provider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
