import axios from "axios";
import { config } from "../config";

// Create `axios` instance passing the newly created `cache.adapter`
export class AuthStore {
  api: any;
  token: string;
  key: string;

  school: any;
  schoolType: any;
  subject: any;
  class: any;
  lesson: any;
  lessonGroup: any;
  
  profile: any;
  loggingIn: boolean;
  loginError: any;

  constructor() {
    this.loggingIn = false;

    try {
      this.restoreLocalInfo();
    } catch {
      this.profile = null;
      this.token = null;
      this.school = null;
    }

    this.api = axios.create({});

    // Add a response interceptor
    this.api.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
    
    // Add a request interceptor
    this.api.interceptors.request.use(
      function (config) {
        
        let token = localStorage.getItem('token');
        if (token !== undefined && token !== null && token.length > 0) {
          config.headers["Authorization"] = `JWT ${token}`;
        }
        
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );    
  }

  setKey(key: string) {
    this.key = key;

    localStorage.setItem('key', JSON.stringify(key));
  }

  saveLocalInfo(data) {
    this.token = data.token;
    this.profile = data.user;
    this.school = data.user.school;

    localStorage.setItem('token', this.token);
    localStorage.setItem('profile', JSON.stringify(this.profile));
    localStorage.setItem('school', JSON.stringify(this.school));
  }

  restoreLocalInfo() {
    let token = localStorage.getItem('token');
    let profile = localStorage.getItem('profile');
    let school = localStorage.getItem('school');
    let key = localStorage.getItem('key');
  
    if (token !== undefined)
      this.token = token;

    if (profile !== undefined)
      this.profile = JSON.parse(profile);

    if (school !== undefined)
      this.school = JSON.parse(school);

    if (key !== undefined)
      this.key = JSON.parse(key);
  }

  saveLocalSubject(subject) {
    this.subject = subject;
    localStorage.setItem('subject', JSON.stringify(subject));
  }

  saveLocalSchoolType(schoolType) {
    this.schoolType = schoolType;
    localStorage.setItem('schoolType', JSON.stringify(schoolType));
  }

  saveLocalClass(classE) {
    this.class = classE;
    localStorage.setItem('class', JSON.stringify(classE));
  }

  saveLocalLesson(lesson) {
    this.lesson = lesson;
    localStorage.setItem('lesson', JSON.stringify(lesson));
  }

  saveLocalLessonGroup(lessonGroup) {
    this.lessonGroup = lessonGroup;
    localStorage.setItem('lesson-group', JSON.stringify(lessonGroup));
  }

  getLocalToken() {
    if (this.token !== undefined && this.token !== null)
      return this.token;

    let token = localStorage.getItem('token');
    if (token !== undefined && token !== null)
      return token;

    return "";
  }

  getLocalProfile() {
    if (this.profile !== undefined && this.profile !== null)
      return this.profile;

    let profile = localStorage.getItem('profile');
    if (profile !== undefined && profile !== null)
      return JSON.parse(profile);

    window.location.replace(window.location.origin + "/login/")
  }

  getLocalSchool() {
    if (this.school !== undefined && this.school !== null)
      return this.school;

    let school = localStorage.getItem('school');
    if (school !== undefined && school !== null)
      return JSON.parse(school);

    window.location.replace(window.location.origin + "/login/")
  }

  getLocalSchoolType() {
    if (this.schoolType !== undefined && this.schoolType !== null)
      return this.schoolType;

    let schoolType = localStorage.getItem('schoolType');
    if (schoolType !== undefined && schoolType !== null)
      return JSON.parse(schoolType);

    window.location.replace(window.location.origin + "/login/")
  }

  getLocalSubject() {
    if (this.subject !== undefined && this.subject !== null)
      return this.subject;

    let subject = localStorage.getItem('subject');
    if (subject !== undefined && subject !== null)
      return JSON.parse(subject);

    return null;
  }

  getLocalLesson() {
    if (this.lesson !== undefined && this.lesson !== null)
      return this.lesson;

    let lesson = localStorage.getItem('lesson');
    if (lesson !== undefined && lesson !== null)
      return JSON.parse(lesson);

    window.location.replace(window.location.origin + "/login/")
  }

  getLocalLessonGroup() {
    if (this.lessonGroup !== undefined && this.lessonGroup !== null)
      return this.lessonGroup;

    let lessonGroup = localStorage.getItem('lesson-group');
    if (lessonGroup !== undefined && lessonGroup !== null)
      return JSON.parse(lessonGroup);

    window.location.replace(window.location.origin + "/login/")
  }

  getLocalClass() {
    if (this.class !== undefined && this.class !== null)
      return this.class;

    let classE = localStorage.getItem('class');
    if (classE !== undefined && classE !== null)
      return JSON.parse(classE);

    window.location.replace(window.location.origin + "/login/")
  }

  login = async (email: string, password: string) => {
    this.loggingIn = true;

    try {
      const response = await this.api.post(`${config.getEndpointUri()}/auth/signin/`, {
        email,
        password,
        key: this.key
      });

      if (!response.data.success) {
        this.loginError = "Wrong auth info";
        return response.data;
      } else {
        this.saveLocalInfo(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return error;
    } finally {
      this.loggingIn = false;
    }
  };

  signup = async (email: string, password: string, code: string, activationCode: string,
    name: string, city: string, province: string, region: string, telephone_number: string, zipCode: string) => {

      try {
        const response = await this.api.post(`${config.getEndpointUri()}/auth/signup/`, {
          email: email,
          password: password,
          code: code,
          activation_code: activationCode,
          key: this.key,
          name: name,
          city: city,
          zip_code: zipCode,
          province: province,
          region: region,
          telephone_number: telephone_number
        });

      if (response.data.error != null) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log(error);
      this.loginError = error.response;
    } finally {
      this.loggingIn = false;
    }
  };

  sendOTP = async (email: string) => {
    try {
      const response = await this.api.post(`${config.getEndpointUri()}/auth/send_otp/`, {
        email: email,
      });

      if (response.data.error != null) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  passwordReset = async (code: string, email: string) => {
    try {
      const response = await this.api.post(`${config.getEndpointUri()}/auth/reset_password/`, {
        code: code,
        email: email,
      });

      if (response.data.error != null) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  searchSchool(key) {
    return this.api.get(`${config.getEndpointUri()}/registry/schools/search/?key=${key}`);
  }

  getSchools(searchKey = null) {
    if (searchKey == null || searchKey.length == 0)
      return this.api.get(`${config.getEndpointUri()}/registry/schools/`);

    return this.api.get(`${config.getEndpointUri()}/registry/schools/?key=${searchKey}`);
  }

  getSchool(id) {
    return this.api.get(`${config.getEndpointUri()}/registry/schools/${id}/`);
  }

  addSchool(data) {
    return this.api.post(`${config.getEndpointUri()}/registry/schools/`, data);
  }

  editSchool(id, data) {
    return this.api.put(`${config.getEndpointUri()}/registry/schools/${id}/`, data);
  }

  editSchoolLessonGroups(id, data) {
    return this.api.put(`${config.getEndpointUri()}/registry/schools/${id}/lesson_groups/`, data);
  }

  getAllSubjects() {
    return this.api.get(`${config.getEndpointUri()}/registry/subjects/`);
  }

  getAllClasses() {
    return this.api.get(`${config.getEndpointUri()}/registry/classes/`);
  }

  getSubjectsOfSchool(id) {
    return this.api.get(`${config.getEndpointUri()}/school/${id}/subjects/`);
  }

  getClassesOfSchool(id) {
    return this.api.get(`${config.getEndpointUri()}/registry/${id}/classes/`);
  }

  getLessonGroupsOfSchool(schoolId, subjectId, classId) {
    return this.api.get(`${config.getEndpointUri()}/content/lesson-groups/?school=${schoolId}&subject=${subjectId}&class=${classId}`);
  }

  getLessonsOfGroup(id, type) {
    return this.api.get(`${config.getEndpointUri()}/content/lessons/?type=${type}&group=${id}`);
  }

  getLessonsOfBett(type) {
    return this.api.get(`${config.getEndpointUri()}/content/lessons/?type=${type}&key=BETT`);
  }

  getUsers(searchKey) {
    if (searchKey == null || searchKey.length == 0)
      return this.api.get(`${config.getEndpointUri()}/registry/users/`);

    return this.api.get(`${config.getEndpointUri()}/registry/users/?key=${searchKey}`);
  }

  getUsersOfSchool(id) {
    return this.api.get(`${config.getEndpointUri()}/registry/users/?school=${id}`);
  }

  getUser(id) {
    return this.api.get(`${config.getEndpointUri()}/registry/users/${id}/`);
  }
  
  addUser(data) {
    return this.api.post(`${config.getEndpointUri()}/registry/users/`, data);
  }

  editUser(id, data) {
    return this.api.put(`${config.getEndpointUri()}/registry/users/${id}/`, data);
  }

  editPassword(id, password) {
    return this.api.post(`${config.getEndpointUri()}/registry/users/${id}/set_password/`, {password: password});
  }

  uploadMedia(data, onUploadProgressCallback) {
    return this.api.post(`${config.getEndpointUri()}/content/lessons/media/`, data, 
    {
      onUploadProgress: (progressEvent) => {
        onUploadProgressCallback((progressEvent.loaded / progressEvent.total) * 100);
      },
    });
  }

  getLessons(searchKey = null) {
    if (searchKey == null || searchKey.length == 0)
      return this.api.get(`${config.getEndpointUri()}/content/lessons/`);

    return this.api.get(`${config.getEndpointUri()}/content/lessons/?key=${searchKey}`);
  }

  getLessonsFiltered(lessonGroup) {
    return this.api.get(`${config.getEndpointUri()}/content/lessons/?group=${lessonGroup.id}`);
  }

  addLesson(data) {
    return this.api.post(`${config.getEndpointUri()}/content/lessons/`, data);
  }

  editLesson(id, data) {
    return this.api.put(`${config.getEndpointUri()}/content/lessons/${id}/`, data);
  }

  deleteLesson(id) {
    return this.api.delete(`${config.getEndpointUri()}/content/lessons/${id}/`);
  }

  getLesson(id) {
    return this.api.get(`${config.getEndpointUri()}/content/lessons/${id}/`);
  }

  getLessonGroups() {
    return this.api.get(`${config.getEndpointUri()}/content/lesson-groups/`);
  }

  getLessonGroupsFiltered(subject, classE, isDemo, type) {
    return this.api.get(`${config.getEndpointUri()}/content/lesson-groups/?subject=${subject.id}&class=${classE.id}&is_demo=${isDemo}&type=${type}`);
  }

  getLessonGroupsBett(type) {
    return this.api.get(`${config.getEndpointUri()}/content/lesson-groups/search/?type=${type}&key=BETT`);
  }

  getLessonGroup(id) {
    return this.api.get(`${config.getEndpointUri()}/content/lesson-groups/${id}/`);
  }

  addLessonGroup(data) {
    return this.api.post(`${config.getEndpointUri()}/content/lesson-groups/`, data);
  }

  deleteLessonGroup(id) {
    return this.api.delete(`${config.getEndpointUri()}/content/lesson-groups/${id}/`);
  }

  editLessonGroup(id, data) {
    return this.api.put(`${config.getEndpointUri()}/content/lesson-groups/${id}/`, data);
  }

  getCodes(value, searchKey) {
    let url = `${config.getEndpointUri()}/registry/activation-codes/?status=${value}`;

    if (searchKey !== null && searchKey !== undefined && searchKey.length > 0)
      url += `&key=${searchKey}`;

    return this.api.get(url);
  }

  addCodes(data) {
    return this.api.post(`${config.getEndpointUri()}/registry/activation-codes/generate/`, data);
  }

  addCode(data) {
    return this.api.post(`${config.getEndpointUri()}/registry/activation-codes/`, data);
  }

  getCode(id) {
    return this.api.get(`${config.getEndpointUri()}/registry/activation-codes/${id}/`);
  }

  editCode(id, data) {
    return this.api.put(`${config.getEndpointUri()}/registry/activation-codes/${id}/`, data);
  }

  resetCode(id) {
    return this.api.post(`${config.getEndpointUri()}/registry/activation-codes/${id}/reset/`);
  }

  getVideo = async (url, onUploadProgressCallback) => {
    let response = await this.api.get(url, {
      onUploadProgress: (progressEvent) => {
        if (onUploadProgressCallback)
          onUploadProgressCallback((progressEvent.loaded / progressEvent.total) * 100);
      },
    });
    return response.data;
  }

  downloadMedia = async (media, onDownloadProgressCallback) => {
    let response = await this.api.get(media.url, 
      { 
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          if (onDownloadProgressCallback)
            onDownloadProgressCallback(media, (progressEvent.loaded / progressEvent.total) * 100);
        },
      }
    );

    return response.data;
  }

  logout = () => {
    //this.profile = null;
    window.localStorage.clear();
    window.location.replace(window.location.origin + "/login/")
  };
}
