import { useStores } from '../stores/store';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { extractUrls } from '../utils/utils';
import { db } from '../models/db';
import { useLiveQuery } from "dexie-react-hooks";
import HashLoader from 'react-spinners/HashLoader';

function MediaSyncPage(props) {
    type MediaElement = {
        url: string;
        percent: number;
        alreadyDownloaded: boolean;
    }

    const downloadedMediaItems = useLiveQuery(
        () => db.videoList.toArray()
    );
    const { auth } = useStores();
	let navigate = useNavigate();
    const [isUnsupported, setIsUnSupported] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasLoadedCache, setHasLoadedCache] = useState<boolean>(false);
    const [mediaElements, setMediaElements] = useState<MediaElement[]>([]);
    const [currentElement, setCurrentElement] = useState<string>(null);
    const [currentPercent, setCurrentPercent] = useState<number>(0);
    const [downloadedItemCount, setDownloadedItemCount] = useState<number>(0);

    useEffect(() => {
        if (downloadedMediaItems !== null && downloadedMediaItems !== undefined) {
            setHasLoadedCache(true);
        }
    }, [downloadedMediaItems])

    useEffect(() => {
        if (hasLoadedCache)
            getUrls();
    }, [hasLoadedCache])

    useEffect(() => {
        if (!isLoading)
            fetchMediaElements();
    }, [isLoading])

    const fetchMediaElements = async () => {
        let localMedia = [...mediaElements];

        for (let i = 0; i < localMedia.length; i++) {
            if (!localMedia[i].alreadyDownloaded)
                await downloadVideo(localMedia[i]);
        }

        goNext();
    }

    const getUrls = async () => {
        let school = auth.getLocalSchool();

        let urls = await extractUrls(school?.license, auth);
        if (urls.length == 0) {
            goNext();
        }

        let localElms = [];

        for (let i = 0; i < urls.length; i++) {        
            let alreadyDownloaded = false;

            if (downloadedMediaItems.filter((item) => item.url == urls[i]).length > 0) {
                alreadyDownloaded = true;
                setDownloadedItemCount((prevValue) => prevValue + 1);
            }
            let me: MediaElement = {
                url: urls[i],
                percent: alreadyDownloaded ? 100 : 0,
                alreadyDownloaded: alreadyDownloaded
            };  
            localElms.push(me);
        }

        setMediaElements(localElms);
        setIsLoading(false);
    }

    const callback = (media, p) => {
        setCurrentPercent(p.toFixed(2));

        if (p < 100)
            return;

        setDownloadedItemCount((prevValue) => prevValue + 1);

        //setMediaElements(mediaElements.filter(item =>
        //    item.url !== media.url));
    }

    const downloadVideo = async (mediaElement) => {
        setCurrentElement(mediaElement.url.substring(mediaElement.url.lastIndexOf('/') + 1));
        let data = await auth.downloadMedia(mediaElement, callback);

        try {
            await db.videoList.add({
                url: mediaElement.url,
                data: data
            })
        } catch (e) {
            console.log("Key already present, skipping");
        }
    };

    const goNext = () => {
        if (auth.profile.type === 1) {
            navigate('/admin/dashboard');
        } else if (auth.profile.type === 2)
            navigate('/user/school-type-selection');
        else if (auth.profile.type === 3)
            navigate('/school/dashboard');
        else if (auth.profile.type === 4)
            navigate('/user/school-type-selection');
    }

    const renderNextButton = () => {
        if (auth.profile.type === 1)
            return <Link to='/admin/dashboard' className="bg-primary text-white font-bold py-2 px-4 rounded-full mb-4">
                Salta
            </Link>;
        else if (auth.profile.type === 2)
            return <Link to='/user/school-type-selection' className="bg-primary text-white font-bold py-2 px-4 rounded-full mb-4">
                Salta
            </Link>;
        else if (auth.profile.type === 3)
            return <Link to='/school/dashboard' className="bg-primary text-white font-bold py-2 px-4 rounded-full mb-4">
                Salta
            </Link>;
        else if (auth.profile.type === 4)
            return <Link to='/user/school-type-selection' className="bg-primary text-white font-bold py-2 px-4 rounded-full mb-4">
                Salta
            </Link>;
        
        return <div></div>;
    }

    if (isUnsupported)
        return (<div className="grid h-screen place-items-center w-full">Il tuo browser non supporta MIRI.</div>);

    if (isLoading)
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    return (
        <div className='grid h-screen place-items-center'>
            <div className="w-full flex flex-col items-center justify-center">
                <div>
                    { renderNextButton() }
                </div>
                <div className='w-1/3'>
                    <div className='flex flex-col mb-4'>
                        <span>Sto scaricando:</span>
                        <div className='flex flex-row justify-between'>
                            <span>{currentElement}</span>
                            <span></span>
                        </div>
                    </div>
                    <div className="relative mb-5 h-4 rounded-full bg-gret-200">
                        <div className="h-4 rounded-full bg-primary" style={{width: currentPercent + "%"}}></div>
                            <span className="absolute inset-0 flex items-center justify-center text-sm font-medium text-gray-900">{currentPercent}%</span>
                        </div>
                    <div className='flex flex-row mb-4'>
                        <span>In coda: {mediaElements.length - downloadedItemCount}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MediaSyncPage;
