import { useStores } from "../../stores/store";
import { AdminHeader } from "./AdminHeader";
import { SchoolHeader } from "./SchoolHeader";

export function Container(props: any) {
  const { auth } = useStores();

  return (
    <div className="w-full">
        { auth.profile?.type == 1 && <AdminHeader />}
        { auth.profile?.type == 3 &&  <SchoolHeader />}
        <div className="min-h-screen bg-lightblue text-gray-900">
          <main className="px-4 sm:px-6 lg:px-8 pt-4">
            <div className="mt-4">
              {props.children}
            </div>
          </main>
        </div>
    </div>
  );
}