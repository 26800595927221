import { useQuery } from '@tanstack/react-query'
import { useEffect, useRef, useState } from "react";
import React from 'react'
import Popup from 'reactjs-popup';
import { useTable, useSortBy } from 'react-table'
import { useStores } from '../../../stores/store'
import { Link } from 'react-router-dom';
import { Container } from '../../common/Container'
import HashLoader from 'react-spinners/HashLoader';
import { FaEdit, FaThumbsDown, FaThumbsUp, FaTrash } from 'react-icons/fa';

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
    useSortBy
  )

  return (<>

    <div className="w-full mt-2 flex flex-col shadow-2xl">
      <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-primary sm:rounded-lg">
            <table {...getTableProps()} className="min-w-full divide-y divide-primary bg-white">
              <thead className="bg-gray-50">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody className="bg-white divide-y divide-primary" {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                    return (
                      <tr className="hover:bg-primary-light" {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return <td className="px-6 py-4 whitespace-nowrap" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                      </tr>
                    )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </>);
}

function AdminLessonsPage() {
  const { auth } = useStores();
  const [confirmId, setConfirmId] = useState<number>(null);
  const [isConfirmShown, setIsConfirmShown] = useState<boolean>();
  const searchKey = useRef<string>("");
  const searchRef = useRef(null);
  const lessonsData = useQuery({
    queryKey: [],
    queryFn: () => auth.getLessons(searchKey.current).then(res => res.data),
    enabled: false
  });

  useEffect(() => {
    searchKey.current = localStorage.getItem('lesson_searchKey');
    lessonsData.refetch();
  }, [])
  
  const onDelete = (id) => {
    setConfirmId(id);
  }

  const doDelete = async () => {
    await auth.deleteLesson(confirmId);
    setConfirmId(null);
    lessonsData.refetch();
  }

  const search = event => {
    if (event.target.value && event.target.value.length < 4 )
      return;

    localStorage.setItem('lesson_searchKey', event.target.value);

    searchKey.current = event.target.value;
    lessonsData.refetch();
  }

  const columns = React.useMemo(
      () => [
      {
          Header: "Lezioni",
          columns: [
          {
              Header: "Modifica",
              accessor: "id",
              Cell: ({ cell }) => (
                <Link reloadDocument to={`/admin/dashboard/lessons/${cell.row.values.id}/`}>
                  <FaEdit />
                </Link>
              )
          },
          {
            Header: 'Nome interno',
            accessor: 'name',
          },
          {
              Header: 'Titolo',
              accessor: 'title',
          },
          {
              Header: 'Descrizione',
              accessor: 'description',
          },
          {
            Header: 'Formato',
            accessor: 'screen_ratio',
          },
          {
            Header: 'Completato',
            accessor: 'is_completed',
            Cell: ({ cell }) => (
              <div>
                { cell.row.values.is_completed && <FaThumbsUp className='text-green'/> }
                { !cell.row.values.is_completed && <FaThumbsDown className='text-red'/> }
              </div>
            )
          },
          {
            Header: "Elimina",
            accessor: "ida",
            Cell: ({ cell }) => (
              <FaTrash onClick={() => onDelete(cell.row.values.id)} className='cursor-pointer' />
            )
          },
          ],
      },
      ],
      []
  );

  if (lessonsData == null || lessonsData.isLoading || lessonsData.data == null)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  return (
    <Container>
      <Popup open={confirmId !== null}
        position="right center"
        closeOnDocumentClick
      >
        <div className="flex flex-col items-center gap-6 p-6">
          <span>Sei sicuro di voler eliminare questo modulo?</span>
          <div className="flex flex-row items-center gap-6">
              <button onClick={() => doDelete()} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Sì, elimina</button>
              <button onClick={() => setConfirmId(null)} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">No</button>
          </div>
        </div>
      </Popup>
      <div className="flex flex-col gap-6 justify-start items-start">
        <div className='flex flex-row mt-4 space-x-2'>
          <Link reloadDocument className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" to="/admin/dashboard/lessons/new">Aggiungi</Link>
          <input onChange={search} ref={searchRef} defaultValue={searchKey.current} placeholder="Cerca (min 4 caratteri)" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <Table columns={columns} data={lessonsData.data} />
      </div>
    </Container>
  )
}

export default AdminLessonsPage
