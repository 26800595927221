import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import { useStores } from "../../../../stores/store";
import { Container } from "../../../common/Container";
import HashLoader from "react-spinners/HashLoader";

export default function AdminNewLessonGroupPage() {
    const { auth } = useStores();
    const params = useParams();
    const [lessons, setLessons] = useState<any[]>([]);
    let navigate = useNavigate();
    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data => postData(data);

    useEffect(() => {
        reset();
    }, []);

    const lessonGroupData = useQuery({
        queryKey: ['1'],
        queryFn: () => auth.getLessonGroup(params.id).then(res => {
            setLessons(res.data.lessons);
            return res.data
        }),
        enabled: params.id != "new"
    });

    const lessonsData = useQuery({
        queryKey: ['2'],
        queryFn: () => auth.getLessons().then(res => res.data),
    });

    const subjectData = useQuery({
        queryKey: ['3'],
        queryFn: () => auth.getAllSubjects().then(res => res.data),
    });

    const classData = useQuery({
        queryKey: ['4'],
        queryFn: () => auth.getAllClasses().then(res => res.data),
    });

    useEffect(() => {
        setValue('name', lessonGroupData.data?.name, { shouldValidate: true })
        setValue('description', lessonGroupData.data?.description, { shouldValidate: true })
        setValue('priority', lessonGroupData.data?.priority, { shouldValidate: true })
        setValue('is_completed', lessonGroupData.data?.is_completed, { shouldValidate: true })
        setValue('is_demo_enabled', lessonGroupData.data?.is_demo_enabled, { shouldValidate: true })
    }, [lessonGroupData.data]);

    useEffect(() => {
        if (subjectData.data != null) {
            setValue('fk_subject', lessonGroupData.data?.fk_subject, { shouldValidate: true })
        }
    }, [subjectData.data, lessonGroupData.data]);

    useEffect(() => {
        if (subjectData.data != null) {
            setValue('fk_class', lessonGroupData.data?.fk_class, { shouldValidate: true })
        }
    }, [classData.data, lessonGroupData.data]);

    const isLessonAssociated = (id) => {
        for (let i = 0; i < lessons?.length; i++) {
            let lesson = lessons[i];

            if (lesson.id == id) {
                return true;
            }
        }

        return false;
    }

    const changeLessonAssociation = (lesson) => {
        if (isLessonAssociated(lesson.id)) {
            setLessons(lessons.filter(obj => obj.id !== lesson.id));
        } else {
            setLessons([...lessons, lesson]);
        }
    }

    const postData = async (data) => {
        data['lessons'] = lessons;

        const formData = new FormData();
        if (params.id != "new") {
            await auth.editLessonGroup(params.id, data);
        } else {
            let lesson = await auth.addLessonGroup(data);
        }

        navigate(`/admin/dashboard/lesson-groups/`);
    }

    if (params.id != "new" && ((lessonsData == null || lessonsData.isLoading || lessonsData.data == null) ||
        (lessonGroupData == null || lessonGroupData.isLoading || lessonGroupData.data == null) ||
        (subjectData == null || subjectData.isLoading || subjectData.data == null) ||
        (classData == null || classData.isLoading || classData.data == null)))
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    return (
        <Container>
            <div className="flex flex-row gap-6 justify-start items-start">

                <div className="bg-white w-1/3">
                    <form className="w-full bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Nome</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("name", { required: true })} />
                            {errors.nameRequired && <span>Questo campo è obbligatorio</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Descrizione</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("description", { required: true })} />
                            {errors.descriptionRequired && <span>Questo campo è obbligatorio</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Materia</label>
                            <select className="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("fk_subject", { required: true })}>
                                {
                                    subjectData.data?.map((subject) => (
                                        <option value={subject.id}>{subject.name}</option>
                                    ))
                                }
                            </select>
                            {errors.fk_subjectRequired && <span>Questo campo è obbligatorio</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Classe</label>
                            <select className="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("fk_class", { required: true })}>
                                {
                                    classData.data?.map((classE) => (
                                        <option value={classE.id}>{classE.name}</option>
                                    ))
                                }

                            </select>
                            {errors.fk_classRequired && <span>Questo campo è obbligatorio</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Priorità</label>
                            <input {...register("priority")} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Completato?</label>
                            <input type="checkbox" {...register("is_completed")} />
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Disponibile come demo?</label>
                            <input type="checkbox" {...register("is_demo_enabled")} />
                        </div>

                        <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type="submit">Salva</button>
                        <button className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full" onClick={() => navigate('/admin/dashboard/lesson-groups')}>Annulla</button>
                    </form>
                </div>
                <div className="flex flex-col items-stretch gap-6 w-2/3">
                    <div className="w-full bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl">
                        <table className="min-w-full divide-y divide-primary bg-white">
                            <thead>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Abilitato</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modulo</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Descrizione</th>
                            </thead>
                            { lessonsData.data?.map((lesson) => (
                                <tr>
                                    <td className='px-6 py-4 whitespace-nowrap'>
                                        <input type="checkbox" defaultChecked={isLessonAssociated(lesson.id)} onChange={() => changeLessonAssociation(lesson)} />
                                    </td>
                                    <td className='px-6 py-4 whitespace-nowrap'>
                                        <span className="rounded-3xl p-2 text-black font-bold m-1">{lesson.name}</span>
                                    </td>
                                    <td className='px-6 py-4 whitespace-nowrap'>
                                        <span className="rounded-3xl p-2 text-black font-bold m-1">{lesson.description}</span>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
            </div>
        </Container>
    );
}
