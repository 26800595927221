import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams, useNavigate } from 'react-router-dom';
import HashLoader from "react-spinners/HashLoader";
import { useStores } from "../../../stores/store";
import { Container } from "../../common/Container";

export default function AdminUserEditPage() {
    const { auth } = useStores();
    const { id } = useParams();
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    let navigate = useNavigate();
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => postData(data);

    const userData = useQuery({
        queryKey: ['1'],
        queryFn: () => auth.getUser(id).then(res => res.data),
        enabled: id !== "new"
    });

    const schoolsData = useQuery({
        queryKey: ['2'],
        queryFn: () => auth.getSchools().then(res => res.data),
    });

    useEffect(() => {
        if (id === "new")
            setIsPasswordShown(true);
    }, []);

    useEffect(() => {
        setValue('name', userData.data?.name, { shouldValidate: true })
        setValue('surname', userData.data?.surname, { shouldValidate: true })
        setValue('email', userData.data?.email, { shouldValidate: true })
        setValue('fk_school', userData.data?.fk_school, { shouldValidate: true })
    }, [userData.data]);

    const postData = async (data) => {
        if (isPasswordShown) {
            if (password.length == 0 || confirmPassword.length == 0) {
                setError("Password non valida");
                return;
            }
            if (password != confirmPassword) {
                setError("Password non valida");
                return;
            }
        }
        if (id !== "new") {
            await auth.editUser(id, data);
            await auth.editPassword(id, password);
        } else {
            let resp = await auth.addUser(data);
            console.log(resp);
            await auth.editPassword(resp.data.id, password);
        }

        navigate(`/admin/dashboard/users/`);
    }

    if (schoolsData == null || schoolsData.isLoading || schoolsData.data == null)
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    return (
        <Container>
            <div className="flex flex-col gap-6 justify-start items-start">
                <Link reloadDocument className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" to="/admin/dashboard/users/">Indietro</Link>

                <div className="bg-white">
                    <form className="w-full bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Nome</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("name", { required: true })} />
                            {errors.nameRequired && <span>Questo campo è obbligatorio</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Cognome</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("surname", { required: true })} />
                            {errors.surnameRequired && <span>Questo campo è obbligatorio</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">E-mail</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("email", { required: true })} />
                            {errors.emailRequired && <span>Questo campo è obbligatorio</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Tipo</label>
                            <select className="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("type")}>
                                <option value="1">Admin</option>
                                <option value="2">Professore</option>
                                <option value="3">Scuola</option>
                                <option value="4">Demo</option>
                            </select>
                            {errors.typeRequired && <span>Questo campo è obbligatorio</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Scuola</label>
                            <select className="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("fk_school")}>
                                <option value="">Nessuna</option>
                                {
                                    schoolsData.data.map((school) => (
                                        <option value={school.id}>{school.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        { id !== "new" && <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => setIsPasswordShown(!isPasswordShown)}>Modifica password</button>}
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
                            <input type="password" onChange={e => setPassword(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Conferma password</label>
                            <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                        </div>
                        { error.length > 0 && <span className="text-red">{error}</span> }
                        <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type="submit">Salva</button>
                        <button className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full" onClick={() => navigate('/admin/dashboard/users')}>Annulla</button>
                    </form>
                </div>
            </div>
        </Container>
    );
}
