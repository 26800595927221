import { useEffect, useRef } from 'react';
import { useStores } from '../../stores/store';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { config } from "../../config";

function ClassSelectionPage(props) {
	const { auth } = useStores();
	let navigate = useNavigate();

	let subject = auth.getLocalSubject();
	let schoolType = auth.getLocalSchoolType();
	let classes = auth.getLocalSchool().classes.filter((myClass) => myClass.type == schoolType);

	const touchEnabled = useRef<boolean>(false);

	useEffect(() => {
	  if (classes.length == 1) {
		auth.saveLocalClass(classes[0]);
		navigate('/user/lesson-group-selection');
	  }

	  setTimeout(() => {
		touchEnabled.current = true;
	}, 250)
	}, [])

	const goToLessons = classElm => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;
		
		auth.saveLocalClass(classElm);
		navigate(`/user/lesson-group-selection`);
	}

	return (
		<div className="grid h-screen place-items-center w-full">
			<video className="absolute -z-10 w-full h-full object-fill" autoPlay loop>
				{ config.isMiri() ?
						<source src="/video/background_miri.mp4" type="video/mp4" />
					:
						<source src="/video/background_pinguin.mp4" type="video/mp4" />
				}
			</video>

			<div className="flex flex-col items-start justify-center">
				<div className="flex flex-row items-center justify-center">
					<div className="rounded-lg p-2 shadow-2xl pb-16 h-1/2 pr-16 text-white text-3xl" style={{ backgroundColor: subject.color }}>{ subject.name }</div>
					<div className='flex flex-col items-start'>
						<div className={`bg-white border-2 rounded-lg p-2 shadow-2xl shrink-0 w-full`} style={{ borderColor: subject.color }}>
							<form className="flex items-center">
								<div className="flex flex-col w-full items-center justify-center p-8">
									{ classes.map((classElm) => (
										<button onMouseDown={() => goToLessons(classElm)} onTouchStart={() => config.isMiri() ? goToLessons(classElm) : null} key={classElm.id} style={{ backgroundColor: classElm.color }} className="text-4xl rounded-lg p-8 m-4 w-full whitespace-nowrap mb-1">{classElm.name}</button>
									))}
								</div>
							</form>
						</div>
						<div className="flex justify-start items-start gap-1 mt-4">
							<div onMouseDown={() => navigate("/user/subject-selection")} onTouchStart={() => config.isMiri() ? navigate("/user/subject-selection") : null} className='bg-primary p-8 rounded-lg text-white text-4xl text-center'>
								<FaHome />
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}

export default ClassSelectionPage;
