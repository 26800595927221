import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import { useStores } from "../../../stores/store";
import { Container } from "../../common/Container";
import HashLoader from "react-spinners/HashLoader";

export default function AdminSchoolEditPage() {
    const { auth } = useStores();
    const { id } = useParams();
    const [subjects, setSubjects] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([]);
    let navigate = useNavigate();
    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data => postData(data);

    const schoolData = useQuery({
        queryKey: ["1"],
        queryFn: () => auth.getSchool(id).then(res => {
            setSubjects(res.data.subjects);
            setClasses(res.data.classes);

            return res.data
        }),
        enabled: id != "new"
    });

    const subjectsData = useQuery({
        queryKey: ["2"],
        queryFn: () => auth.getAllSubjects().then(res => res.data)
    });

    const classesData = useQuery({
        queryKey: ["3"],
        queryFn: () => auth.getAllClasses().then(res => res.data)
    });

    useEffect(() => {
        reset();
    }, []);

    useEffect(() => {
        setValue('name', schoolData.data?.name, { shouldValidate: true })
        setValue('type', schoolData.data?.type, { shouldValidate: true })
    }, [schoolData.data]);

    const postData = (data) => {
        data['subjects'] = subjects;
        data['classes'] = classes;

        if (id != "new")
            auth.editSchool(id, data);
        else
            auth.addSchool(data);

        navigate(`/admin/dashboard/schools/`);
    }

    const isSubjectAssociated = (id) => {
        for (let i = 0; i < subjects?.length; i++) {
            let subject = subjects[i];

            if (subject.id == id) {
                return true;
            }
        }

        return false;
    }

    const changeSubjectAssociation = (subject) => {
        if (isSubjectAssociated(subject.id)) {
            setSubjects(subjects.filter(obj => obj.id !== subject.id));
        } else {
            setSubjects([...subjects, subject]);
        }
    }

    const isClassAssociated = (id) => {
        for (let i = 0; i < classes?.length; i++) {
            let classE = classes[i];

            if (classE.id == id) {
                return true;
            }
        }

        return false;
    }

    const changeClassAssociation = (classE) => {
        if (isClassAssociated(classE.id)) {
            setClasses(classes.filter(obj => obj.id !== classE.id));
        } else {
            setClasses([...classes, classE])
        }
    }

    if (id !== "new" && ((schoolData === null || schoolData.isLoading || schoolData.data === null) ||
        (subjectsData === null || subjectsData.isLoading || subjectsData.data === null) ||
        (classesData === null || classesData.isLoading || classesData.data === null)))
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    return (
        <Container>
            <div className="w-full flex flex-col gap-6 justify-start items-start">
                <div className="w-full flex flex-row gap-6">
                    <div className="w-1/3 bg-white">
                        <form className="w-full bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl" onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Nome scuola</label>
                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("name", { required: true })} />
                                {errors.nameRequired && <span>Questo campo è obbligatorio</span>}
                            </div>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Tipo scuola</label>
                                <select className="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("type", { required: true })}>
                                    <option value="1">Scuola dell'infanzia</option>
                                    <option value="2">Scuola primaria</option>
                                </select>
                                {errors.typeRequired && <span>Questo campo è obbligatorio</span>}
                            </div>
                    
                            <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type="submit">Salva</button>
                            <button className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full" onClick={() => navigate('/admin/dashboard/schools')}>Annulla</button>
                        </form>
                    </div>
                    <div className="w-2/3 flex flex-col items-stretch gap-6 mx-auto">
                        <div className="w-full bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl">
                            <table className="min-w-full divide-y divide-primary bg-white">
                                <thead>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Abilitato</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Materia</th>
                                </thead>
                                { subjectsData.data?.map((subject) => (
                                    <tr>
                                        <td className='px-6 py-4 whitespace-nowrap'>
                                            <input type="checkbox" defaultChecked={isSubjectAssociated(subject.id)} onChange={() => changeSubjectAssociation(subject)} />
                                        </td>
                                        <td className='px-6 py-4 whitespace-nowrap'>
                                            <span style={{backgroundColor: subject.color}} className="rounded-3xl p-2 text-white font-bold m-1">{subject.name}</span>
                                        </td>
                                    </tr>
                                ))}
                            </table>
                        </div>
                        <div className="w-full bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl">
                            <table className="min-w-full divide-y divide-primary bg-white">
                                <thead>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Abilitato</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Classe</th>
                                </thead>

                                { classesData.data?.map((classE) => (
                                    <tr>
                                        <td className='px-6 py-4 whitespace-nowrap'>
                                            <input type="checkbox" defaultChecked={isClassAssociated(classE.id)} onChange={() => changeClassAssociation(classE)} />
                                        </td>
                                        <td className='px-6 py-4 whitespace-nowrap'>
                                            <span style={{backgroundColor: classE.color}} className="rounded-3xl p-2 text-white font-bold m-1">{classE.name}</span>
                                        </td>
                                    </tr>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}
