import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useTable, useSortBy } from 'react-table'
import { useStores } from '../../../stores/store'
import { Link } from 'react-router-dom';
import { Container } from '../../common/Container'
import HashLoader from 'react-spinners/HashLoader';
import { FaEdit, FaThumbsDown, FaThumbsUp, FaTrash } from 'react-icons/fa';
import Popup from 'reactjs-popup';

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
    useSortBy
  )

  return (
    <div className="w-full mt-2 flex flex-col shadow-2xl">
      <div className="w-full shadow overflow-hidden border-b border-primary sm:rounded-lg mb-4">
        <table {...getTableProps()} className="w-full divide-y divide-primary bg-white overflow-x-hidden">
          <thead className="bg-gray-50">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          
          <tbody className="bg-white divide-y divide-primary" {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
                return (
                  <tr className="hover:bg-primary-light" {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td className="px-6 py-4" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function AdminLessonGroupsPage() {
  const { auth } = useStores();
  const [confirmId, setConfirmId] = useState<number>(null);
  const lessonGroupsData = useQuery({
    queryKey: [],
    queryFn: () => auth.getLessonGroups().then(res => res.data),
  });

  const onDelete = (id) => {
    setConfirmId(id);
  }

  const doDelete = async () => {
    await auth.deleteLessonGroup(confirmId);
    setConfirmId(null);
    lessonGroupsData.refetch();
  }
    
  const columns = React.useMemo(
      () => [
      {
          Header: "Moduli",
          columns: [
          {
            Header: "Modifica",
            accessor: "id",
            Cell: ({ cell }) => (
              <Link reloadDocument to={
                  `/admin/dashboard/lesson-groups/${cell.row.values.id}`}>
                <FaEdit />
              </Link>
            )
          },
          {
              Header: 'Nome',
              accessor: 'name',
          },
          {
              Header: 'Descrizione',
              accessor: 'description',
          },
          {
            Header: 'Materia',
            accessor: 'fk_subject_name',
          },
          {
            Header: 'Classe',
            accessor: 'fk_class_name',
          },
          {
            Header: 'Priorità',
            accessor: 'priority',
          },
          {
            Header: "Lezioni",
            accessor: "lessons",
            Cell: ({ cell }) => (
              cell.row.values.lessons.length
            )
          },
          {
            Header: 'Completato',
            accessor: 'is_completed',
            Cell: ({ cell }) => (
              <div>
                { cell.row.values.is_completed && <FaThumbsUp className='text-green'/> }
                { !cell.row.values.is_completed && <FaThumbsDown className='text-red'/> }
              </div>
            )
          },
          {
            Header: 'Demo?',
            accessor: 'is_demo_enabled',
            Cell: ({ cell }) => (
              <div>
                { cell.row.values.is_demo_enabled && <FaThumbsUp className='text-green'/> }
                { !cell.row.values.is_demo_enabled && <FaThumbsDown className='text-red'/> }
              </div>
            )
          },
          {
            Header: "Elimina",
            accessor: "ida",
            Cell: ({ cell }) => (
              <FaTrash onClick={() => onDelete(cell.row.values.id)} className='cursor-pointer' />
            )
          },
          ],
      },
      ],
      []
  );

  if (lessonGroupsData == null || lessonGroupsData.isLoading || lessonGroupsData.data == null)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  return (
    <Container>
      <Popup open={confirmId !== null}
        position="right center"
        closeOnDocumentClick
      >
        <div className="flex flex-col items-center gap-6 p-6">
          <span>Sei sicuro di voler eliminare questa lezione?</span>
          <div className="flex flex-row items-center gap-6">
              <button onClick={() => doDelete()} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Sì, elimina</button>
              <button onClick={() => setConfirmId(null)} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">No</button>
          </div>
        </div>
      </Popup>
      <div className="flex flex-col gap-6 justify-start items-start">
        <Link reloadDocument className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" to="/admin/dashboard/lesson-groups/new">Aggiungi lezione</Link>
        <Table columns={columns} data={lessonGroupsData.data} />
      </div>
    </Container>
  )
}

export default AdminLessonGroupsPage;
