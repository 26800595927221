import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useTable, useSortBy } from 'react-table'
import { useStores } from '../../../stores/store'
import { Link } from 'react-router-dom';
import { Container } from '../../common/Container'
import HashLoader from 'react-spinners/HashLoader';
import { config } from "../../../config";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
    useSortBy
  )

  return (
    <div className="w-full mt-2 flex flex-col shadow-2xl">
      <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-primary sm:rounded-lg">
            <table {...getTableProps()} className="min-w-full divide-y divide-primary bg-white">
              <thead className="bg-gray-50">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              
              <tbody className="bg-white divide-y divide-primary" {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                    return (
                      <tr className="hover:bg-primary-light" {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return <td className="px-6 py-4 whitespace-nowrap" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                      </tr>
                    )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

function SchoolLessonGroupsPage() {
  const { auth } = useStores();
  const lessonGroupsData = useQuery({
    queryKey: [],
    queryFn: () => auth.getLessonGroups().then(res => res.data),
  });
    
  const columns = React.useMemo(
      () => [
      {
          Header: "Moduli",
          columns: [
          {
              Header: 'Nome',
              accessor: 'name',
          },
          {
              Header: 'Descrizione',
              accessor: 'description',
          },
          {
            Header: 'Materia',
            accessor: 'fk_subject_name',
          },
          {
            Header: 'Classe',
            accessor: 'fk_class_name',
          },
          {
            Header: "Lezioni",
            accessor: "lessons",
            Cell: ({ cell }) => (
              cell.row.values.lessons.length
            )
          },
          {
            Header: "Thumbnail",
            accessor: "thumbnail_url",
            Cell: ({ cell }) => (
              cell.row.values?.thumbnail_url != "" && (
                <img className="w-32" src={config.mediaEndpoint.concat(cell.row.values.thumbnail_url)} />
              )
            )
          },
          ],
      },
      ],
      []
  );

  if (lessonGroupsData == null || lessonGroupsData.isLoading || lessonGroupsData.data == null)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  return (
    <Container>
      <div className="flex flex-col gap-6 justify-start items-start">
        <Link reloadDocument className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" to="/school/dashboard/lesson-groups/new">Aggiungi lezione</Link>
        <Table columns={columns} data={lessonGroupsData.data} />
      </div>
    </Container>
  )
}

export default SchoolLessonGroupsPage;
