import { DataType } from "../models/models";

export function halve(x, isFirst: boolean) {
    if (isFirst)
        return x.filter((i, idx) => idx < Math.ceil(x.length / 2))
    else
        return x.filter((i, idx) => idx >= Math.ceil(x.length / 2))
}

export function extractLessonUrls(data) {
    let tempUrls = [];

    data = JSON.parse(data)['data'];

    if (data['background']) {
        if (data['background'].dataType == DataType.video) {
            tempUrls.push(data['background'].dataUrl);
        }
    }

    for (let i = 0; i < data['widgets'].length; i++) {
        let parsedW = data['widgets'][i];
        if (parsedW.dataType == DataType.video) {
            tempUrls.push(parsedW.dataUrl);
        }
    }

    return tempUrls;
}

export async function extractUrls(license, auth) {
    let urls = [];

    let data = await auth.getLessons();

    for (let i = 0; i < data.data.length; i++) {
        let lesson = data.data[i];

        if (license == 1) { /* Pinguin */
            if (lesson.screen_ratio != "16_9")
                continue;
        } else if (license == 2 ) { /* MIRI */
            if (lesson.screen_ratio != "48_9")
                continue;
        }

        let tempUrls = extractLessonUrls(lesson['content']);
        urls = urls.concat(tempUrls);
    }

    return urls;
}