import { useEffect } from 'react';
import { useStores } from '../../stores/store';
import { Link, useNavigate } from 'react-router-dom';
import { config } from "../../config";

function SchoolTypeSelectionPage(props) {
	const { auth } = useStores();
	let school = auth.getLocalSchool();
	let navigate = useNavigate();

	const goToSubject = schoolType => {
		auth.saveLocalSchoolType(schoolType);
		navigate('/user/subject-selection');
	}
	
	const goToBett = () => {
		navigate('/user/bett-lesson-group-selection');
	}

	/*
	useEffect(() => {
		if (auth.getLocalSchool().is_childhood_present && !auth.getLocalSchool().is_primary_present) {
			goToSubject(1);
		}
		if (auth.getLocalSchool().is_primary_present && !auth.getLocalSchool().is_childhood_present) {
			goToSubject(2);
		}
	}, [])
	*/

	return (
		<div className="grid h-screen place-items-center w-full">
			<video className="absolute -z-10 w-full h-full object-fill" autoPlay loop>
				{ config.isMiri() ?
						<source src="/video/background_miri.mp4" type="video/mp4" />
					:
						<source src="/video/background_pinguin.mp4" type="video/mp4" />
				}
			</video>
			<div className="flex flex-col items-center justify-center ">
				<div className="border-2 border-primary bg-white rounded-lg p-2 shadow-2xl">
					<div className="flex items-center">
						<div className="flex flex-col items-center justify-start m-8">
							<button onMouseDown={() => goToSubject(1)} onTouchStart={() => config.isMiri() ? goToSubject(1) : null} className="bg-primary text-white text-4xl rounded-lg p-8 w-full whitespace-nowrap mb-1">
								Scuola dell'infanzia
							</button>
							{ auth.getLocalSchool().is_primary_present ?
								<button onMouseDown={() => goToSubject(2)} onTouchStart={() => config.isMiri() ? goToSubject(2) : null} className="bg-primary text-white text-4xl rounded-lg p-8 w-full whitespace-nowrap mb-1">
									Scuola primaria
								</button> : null
							}
							<button onMouseDown={() => goToBett()} onTouchStart={() => config.isMiri() ? goToBett() : null} className="bg-primary text-white text-4xl rounded-lg p-8 w-full whitespace-nowrap mb-1">
								BETT
							</button>
						</div>
					</div>
				</div>
				<div className="flex justify-start items-center gap-1 mt-4 w-full ml-8">
					<Link to="/" className='bg-primary p-8 rounded-lg text-white text-4xl text-center'>
						LOGOUT
					</Link>
				</div>
			</div>
		</div>
	)
}

export default SchoolTypeSelectionPage;
