import { useQuery } from '@tanstack/react-query';
import { useStores } from '../../stores/store';
import { Link, useNavigate } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';
import { FaArrowLeft, FaHome } from 'react-icons/fa';
import { config } from "../../config";
import { useEffect, useRef } from 'react';

function LessonSelectionPage(props) {
	const { auth } = useStores();
	let navigate = useNavigate();
	let school = auth.getLocalSchool();
	//let subject = auth.getLocalSubject();
	let lessonGroup = auth.getLocalLessonGroup();
	const touchEnabled = useRef<boolean>(false);

	const lessonsData = useQuery({
		queryKey: [],
		queryFn: () => auth.getLessonsOfGroup(lessonGroup.id, school.license).then(res => res.data),
	});

	useEffect(() => {
		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)
	}, [])

	const goToLesson = lesson => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

		auth.saveLocalLesson(lesson);

		navigate('/user/interactive-lesson?is_bett=true');
	}

	if (lessonsData == null || lessonsData.isLoading || lessonsData.data == null)
		return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

	return (
		<div className="grid h-screen place-items-center w-full">
			<video className="absolute -z-10 w-full h-full object-fill" autoPlay loop>
				{ config.isMiri() ?
						<source src="/video/background_miri.mp4" type="video/mp4" />
					:
						<source src="/video/background_pinguin.mp4" type="video/mp4" />
				}
			</video>
			<div className="flex flex-col items-center justify-center">
				<div className=" bg-primary rounded-t-3xl p-2 text-white text-3xl w-3/4 text-center">{ lessonGroup?.name }</div>
				<div className="bg-white border-2 rounded-lg py-8 px-6 shadow-2xl">
					<form className="flex items-center">
						<div className="grid grid-rows-3 grid-flow-col p-1 gap-x-10 cursor-pointer">
							{ lessonsData.data.map((lesson, index) => (
								<div onMouseDown={() => goToLesson(lesson)} onTouchStart={() => config.isMiri() ? goToLesson(lesson) : null} key={lesson.id} className="rounded-lg p-1 w-full whitespace-nowrap mb-6 flex flex-row justify-start items-center">
									<span className="bg-primary text-white text-4xl rounded-md text-center mr-2 px-3 pt-2">{index+1}</span>
									<div className='flex flex-col'>
										<span className='text-4xl'>{lesson.title}</span>
										<span className='text-2xl'>{lesson.description}</span>
									</div>
								</div>
							))}
						</div>
					</form>
				</div>
				<div className="flex justify-start items-center gap-1 mt-4 w-full ml-8">
					<div onMouseDown={() => navigate("/user/bett-lesson-group-selection")} onTouchStart={() => config.isMiri() ? navigate("/user/bett-lesson-group-selection") : null} className='bg-primary p-8 rounded-lg text-white text-4xl text-center'>
						<FaArrowLeft />
					</div>
					<div onMouseDown={() => navigate("/user/school-type-selection")} onTouchStart={() => config.isMiri() ? navigate("/user/school-type-selection") : null} className="bg-primary rounded-lg p-8 text-white text-4xl text-center">
						<FaHome />
					</div>
				</div>
			</div>
		</div>
	)
}

export default LessonSelectionPage;
