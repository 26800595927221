import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import { useStores } from "../../../stores/store";
import { Container } from "../../common/Container";
import HashLoader from "react-spinners/HashLoader";

export default function AdminCodeEditPage() {
    const { auth } = useStores();
    const { id } = useParams();
    let navigate = useNavigate();
    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data => postData(data);

    const codeData = useQuery({
        queryKey: ["1"],
        queryFn: () => auth.getCode(id).then(res => {
            return res.data
        }),
        enabled: id != "new"
    });

    useEffect(() => {
        reset();
    }, []);

    useEffect(() => {
        setValue('code', codeData.data?.code, { shouldValidate: true })
        setValue('generation_date', codeData.data?.generation_date, { shouldValidate: true })
        setValue('activation_date', codeData.data?.activation_date, { shouldValidate: true })
        setValue('validity_year', codeData.data?.validity_year, { shouldValidate: true })
        setValue('production_lot', codeData.data?.production_lot, { shouldValidate: true })
        setValue('notes', codeData.data?.notes, { shouldValidate: true })
        setValue('commercial_agent', codeData.data?.commercial_agent, { shouldValidate: true })
    }, [codeData.data]);

    const postData = (data) => {
        if (id != "new")
            auth.editCode(id, data);
        else
            auth.addCode(data);

        navigate(`/admin/dashboard/codes/`);
    }

    if (id !== "new" && (codeData === null || codeData.isLoading || codeData.data === null))
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    return (
        <Container>
            <div className="w-full flex flex-col gap-6 justify-start items-start">
                <div className="w-full flex flex-row gap-6">
                    <div className="w-1/3 bg-white">
                        <form className="w-full bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl" onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Codice</label>
                                <input disabled className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("code", { required: true })} />
                                {errors.codeRequired && <span>Questo campo è obbligatorio</span>}
                            </div>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Data di generazione</label>
                                <input disabled className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("generation_date", { required: true })} />
                                {errors.generation_dateRequired && <span>Questo campo è obbligatorio</span>}
                            </div>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Data di attivazione</label>
                                <input disabled className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("activation_date")} />
                            </div>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Tipo</label>
                                <select {...register("type", { required: true })}>
                                    <option value="1">Pinguin</option>
                                    <option value="2">Miri</option>
                                </select>
                                {errors.typeRequired && <span>Questo campo è obbligatorio</span>}
                            </div>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Validità</label>
                                <select className="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("validity_year", { required: true })}>
                                    <option value="1">1 anno</option>
                                    <option value="2">2 anni</option>
                                    <option value="3">3 anni</option>
                                    <option value="4">4 anni</option>
                                    <option value="5">5 anni</option>
                                    <option value="1000">Lifetime</option>
                                </select>
                                {errors.validity_yearRequired && <span>Questo campo è obbligatorio</span>}
                            </div>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Lotto di produzione</label>
                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("production_lot")} />
                            </div>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Note</label>
                                <textarea rows={5} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("notes")}></textarea>
                            </div>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Agente di riferimento</label>
                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("commercial_agent")} />
                            </div>
                    
                            <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type="submit">Salva</button>
                            <button className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full" onClick={() => navigate('/admin/dashboard/codes')}>Annulla</button>
                        </form>
                    </div>
                </div>
            </div>
        </Container>
    );
}
