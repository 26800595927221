import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { useTable } from 'react-table'
import { useStores } from '../../../stores/store'
import { Container } from '../../common/Container'
import {CSVLink } from 'react-csv';
import HashLoader from 'react-spinners/HashLoader';

import Moment from 'moment';
import Popup from 'reactjs-popup'
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';

function Table({ columns, data }) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  return (
    <div className="w-full mt-2 flex flex-col shadow-2xl">
      <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-primary sm:rounded-lg">
            <table {...getTableProps()} className="min-w-full divide-y divide-primary bg-white">
              <thead className="bg-gray-50">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              
              <tbody className="bg-white divide-y divide-primary" {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                    return (
                      <tr className="hover:bg-primary-light" {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return <td className="px-6 py-4 whitespace-nowrap" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                      </tr>
                    )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

function AdminCodesPage() {
  const { auth } = useStores();
  const [addCodesShown, setAddCodesShown] = useState<boolean>(false);
  const [resetCodeId, setResetCodeId] = useState<number>(0);
  const filterValue = useRef<string>("free");
  const searchKey = useRef<string>("");
  const searchRef = useRef(null);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const codesData = useQuery({
    queryKey: [],
    queryFn: () => auth.getCodes(filterValue.current, searchKey.current).then(res => res.data),
  });

  const columns = React.useMemo(
      () => [
      {
          Header: "Codici",
          columns: [
            {
              Header: "Modifica",
              accessor: "id",
              Cell: ({ cell }) => (
                <Link reloadDocument to={
                    `/admin/dashboard/codes/${cell.row.values.id}`}>
                  <FaEdit />
                </Link>
              )
            },
            {
                Header: 'Codice',
                accessor: 'code',
            },
            {
              Header: "Data di generazione",
              accessor: "generation_date",
              Cell: ({ cell }) => (
                Moment(cell.row.values.generation_date).format('DD/MM/yyyy')
              )
            },
            {
              Header: "Data di attivazione",
              accessor: "activation_date",
              Cell: ({ cell }) => (
                cell.row.values.activation_date ? Moment(cell.row.values.activation_date).format('DD/MM/yyyy') : "Non attivato"
              )
            },
            {
              Header: "Validità",
              accessor: "validity_year",
              Cell: props => {
                return props.value == 1000 ? "Lifetime" : props.value + " anno/i"
              }
            },
            {
              Header: "Tipo",
              accessor: "type",
              Cell: props => {
                return props.value == 1 ? "Pinguin" : "Miri"
              }
            },
            {
              Header: "Nome scuola",
              accessor: "school_name",
            },
            {
              Header: "Codice meccanografico",
              accessor: "school_code",
            },
            {
              Header: "Note",
              accessor: "notes",
            },
            {
              Header: "Lotto",
              accessor: "production_lot",
            },
            {
              Header: "Commerciale",
              accessor: "commercial_agent",
            },
            {
              Header: "Reset",
              Cell: props => (
                props.row.values.activation_date ? <button className='bg-primary rounded-3xl p-3' onClick={() => setResetCodeId(props.row.values.id)}>Reset</button> : <span></span>
              )
            },
          ],
      },
    ],
      []
  );

  const generateCodes = () => {
    reset();
    setAddCodesShown(true);
  }

  const save = async (data: any) => {
    await auth.addCodes(data);
    setAddCodesShown(false);
    codesData.refetch();
  }

  const handleFilterChange = e => {
    filterValue.current = e.target.value;
    codesData.refetch();
  }

  const search = event => {
    if (event.target.value && event.target.value.length < 4)
      return;

    searchKey.current = event.target.value;
    codesData.refetch();
  }

  const clear = () => {
    searchKey.current = null;
    searchRef.current.value = "";
    codesData.refetch();
  }

  const handleSubmitReset = async () => {
    await auth.resetCode(resetCodeId);
    codesData.refetch();
  }

  if (codesData == null || codesData.isLoading || codesData.data == null)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  return (
    <Container>
      <Popup open={resetCodeId != 0}
        position="right center"
        closeOnDocumentClick>

        <form onSubmit={handleSubmitReset} className="w-full flex flex-col items-center gap-6 m-4">
          Vuoi veramente resettare questo codice di attivazione?

          <div className="w-full flex flex-row justify-center gap-6">
            <button className="bg-primary text-white font-bold py-2 px-4 rounded-full" type="submit">Sì</button>
            <button onClick={(e) => { e.preventDefault(); setResetCodeId(0) }} className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full">No</button>
          </div>
        </form>
      </Popup>
      <Popup open={addCodesShown}
          position="right center"
          closeOnDocumentClick
      >
        <div className="flex flex-col items-center gap-6 p-6">
          <div className="flex flex-row items-center gap-6">
            <form onSubmit={handleSubmit(save)} className="w-full flex flex-col items-center gap-6">
              <div className="flex flex-col">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Quanti codici vuoi generare?</label>
                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("count", { required: true })} />
                  {errors.countRequired && <span>Questo campo è obbligatorio</span>}
              </div>
              <div className="flex flex-col">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Tipo</label>
                  <select {...register("type", { required: true })} className="bg-primary text-white rounded-full p-2 cursor-pointer">
                    <option value="1">Pinguin</option>
                    <option value="2">Miri</option>
                  </select>
                  {errors.typeRequired && <span>Questo campo è obbligatorio</span>}
              </div>
              <div className="flex flex-col">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Validità</label>
                  <select {...register("validity_year", { required: true })} className="bg-primary text-white rounded-full p-2 cursor-pointer">
                    <option value="1">1 anno</option>
                    <option value="2">2 anno</option>
                    <option value="3">3 anno</option>
                    <option value="4">4 anno</option>
                    <option value="5">5 anno</option>
                    <option value="1000">Lifetime</option>
                  </select>
                  {errors.validityRequired && <span>Questo campo è obbligatorio</span>}
              </div>
              <div className="flex flex-col">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Lotto</label>
                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("lot")} />
              </div>

              <button className="bg-primary text-white font-bold py-2 px-4 rounded-full" type="submit">Genera</button>
              <button onClick={(e) => { e.preventDefault(); setAddCodesShown(false); }} className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full">Annulla</button>
            </form>
          </div>
        </div>
      </Popup>

      <div className="flex flex-col gap-6 justify-start items-start">
        <div>
          <button onClick={generateCodes} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mr-4">Genera codici</button>
          <div className='flex flex-row mt-4 space-x-2'>
            <select className="bg-primary text-white rounded-full p-2 cursor-pointer" onChange={handleFilterChange}>
              <option value="free">Liberi</option>
              <option value="used">Usati</option>
            </select>
            <input onChange={search} ref={searchRef} placeholder="Cerca (min 4 caratteri)" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            <button onClick={clear} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mr-4">Cancella</button>
            <CSVLink className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mr-4" data={codesData.data}>Esporta</CSVLink>
          </div>
        </div>
        <Table columns={columns} data={codesData.data} />
      </div>
    </Container>
  )
}

export default AdminCodesPage
