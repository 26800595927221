import { useQuery } from '@tanstack/react-query'
import { useStores } from '../../stores/store';
import { useNavigate } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';
import { FaHome } from 'react-icons/fa';
import { config } from "../../config";
import { useEffect, useRef } from 'react';

function BettLessonGroupSelectionPage(props) {
	const { auth } = useStores();
	let navigate = useNavigate();
	let school = auth.getLocalSchool();
	//let schoolType = auth.getLocalSchoolType();
	//let subject = auth.getLocalSubject();
	//let classE = auth.getLocalClass();
	//let classes = auth.getLocalSchool().classes.filter((myClass) => myClass.type == schoolType);
    const touchEnabled = useRef<boolean>(false);

	const lessonGroupsData = useQuery({
		queryKey: [],
		queryFn: () => auth.getLessonGroupsBett(school.license).then(res => res.data),
	});

	useEffect(() => {
		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)
	}, [])

	const goToLessonSelection = lessonGroup => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

		auth.saveLocalLessonGroup(lessonGroup);
		navigate('/user/bett-lesson-selection');
	}

	if (lessonGroupsData == null || lessonGroupsData.isLoading || lessonGroupsData.data == null)
		return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

	if (lessonGroupsData.data.length == 0) {
		return (
			<div className="grid h-screen place-items-center">
				<img className="w-1/2" src="/images/tappo.png" />
				{ 
						<div onMouseDown={() => navigate("/user/school-type-selection/")} onTouchStart={() => config.isMiri() ? navigate("/user/school-type-selection/") : null} className='bg-primary p-8 rounded-lg text-white text-4xl text-center'>
							<FaHome />
						</div>
				}
			</div>
		);
	}

	return (
		<div className="grid h-screen place-items-center w-full">
			<video className="absolute -z-10 w-full h-full object-fill" autoPlay loop>
				{ config.isMiri() ?
						<source src="/video/background_miri.mp4" type="video/mp4" />
					:
						<source src="/video/background_pinguin.mp4" type="video/mp4" />
				}
			</video>

			<div className="flex flex-col items-center justify-center">
				<div className="bg-primary rounded-t-2xl p-2 text-white text-3xl w-3/4 text-center">BETT</div>
				<div className="bg-white border-2 border-primary rounded-lg p-2 shadow-2xl">
					<form className="flex items-center">
						<div className="grid grid-cols-2 gap-8 m-8">
							{ lessonGroupsData.data.map((lessonGroup) => (
							<div className={`flex flex-row rounded-lg p-8 w-full whitespace-nowrap mb-1 cursor-pointer bg-primary`} onMouseDown={() => goToLessonSelection(lessonGroup)} onTouchStart={() => config.isMiri() ? goToLessonSelection(lessonGroup) : null} key={lessonGroup.id}>
									<div className="flex flex-col">
										<span className="w-full whitespace-nowrap mb-1 text-white text-4xl">{lessonGroup.name}</span>
									</div>
								</div>
							))}
						</div>
					</form>
				</div>
				<div className="flex justify-start items-center gap-1 mt-4 w-full ml-8">
					<div onMouseDown={() => navigate('/user/school-type-selection/')} onTouchStart={() => config.isMiri() ? navigate('/user/school-type-selection/') : null} className='bg-primary p-8 rounded-lg text-white text-4xl text-center'>
						<FaHome />
					</div>
				</div>
			</div>
		</div>
	)
}

export default BettLessonGroupSelectionPage;
