import { useQuery } from '@tanstack/react-query'
import { useStores } from '../../../stores/store'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container } from '../../common/Container'
import HashLoader from 'react-spinners/HashLoader';

function AdminSchoolLessonGroupsPage() {
  const { auth } = useStores();
  const { id } = useParams();
  let navigate = useNavigate();

  const lessonGroupsData = useQuery({
    queryKey: ['1'],
    queryFn: () => auth.getLessonGroups().then(res => res.data),
  });

  const schoolData = useQuery({
    queryKey: ['2'],
    queryFn: () => auth.getSchool(id).then(res => res.data),
  });

  const isLessonGroupPresent = (lessonGroupId) => {
    for (let i = 0; i < schoolData.data?.lesson_groups?.length; i++) {
      let lg = schoolData.data.lesson_groups[i];
      if (lg.id == lessonGroupId)
        return true;
    }

    return false;
  }

  const changeLessonGroupAssociation = (lessonGroup) => {
    if (isLessonGroupPresent(lessonGroup.id)) {
        schoolData.data.lesson_groups = schoolData.data.lesson_groups.filter(obj => obj.id !== lessonGroup.id);
    } else {
        schoolData.data.lesson_groups.push(lessonGroup);
    }
  }

  const save = async () =>  {
    await auth.editSchoolLessonGroups(id, schoolData.data.lesson_groups);

    navigate(`/admin/dashboard/schools/`);
  }


  if ((lessonGroupsData == null || lessonGroupsData.isLoading || lessonGroupsData.data == null) ||
    (schoolData == null || schoolData.isLoading || schoolData.data == null)
  )
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  return (
    <Container>
      <div className="flex flex-col gap-6 justify-start items-start">
        <div className='w-full flex flex-row justify-between'>
          <span>{schoolData.data.name}</span>
          <div className='flex flex-row gap-2'>
            <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={save}>Salva</button>
            <Link reloadDocument className="border-2 border-primary hover:bg-blue-700 text-primary font-bold py-2 px-4 rounded-full" to="/admin/dashboard/schools">Indietro</Link>
          </div>
        </div>
        <div className="w-full mt-2 flex flex-col shadow-2xl">
          <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-primary sm:rounded-lg">
                <table className="min-w-full divide-y divide-primary bg-white">
                  <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Abilitato</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Descrizione</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Materia</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Classe</th>
                    </tr>
                  </thead>
                  
                  <tbody className="bg-white divide-y divide-primary">
                    {lessonGroupsData.data?.map((row, i) => (
                          <tr className="hover:bg-primary-light">
                            <td className="px-6 py-4 whitespace-nowrap">
                              <input type="checkbox" defaultChecked={isLessonGroupPresent(row.id)} onChange={() => changeLessonGroupAssociation(row)} />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">{row.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{row.description}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{row.fk_subject_name}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{row.fk_class_name}</td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default AdminSchoolLessonGroupsPage;
