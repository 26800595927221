import React, { useRef } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTable } from 'react-table'
import { useStores } from '../../../stores/store'
import { Link } from 'react-router-dom';
import { Container } from '../../common/Container'
import { FaEdit } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import HashLoader from 'react-spinners/HashLoader';

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  return (
    <div className="w-full mt-2 flex flex-col shadow-2xl">
      <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-primary sm:rounded-lg">
            <table {...getTableProps()} className="min-w-full divide-y divide-primary bg-white">
              <thead className="bg-gray-50">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              
              <tbody className="bg-white divide-y divide-primary" {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                    return (
                      <tr className="hover:bg-primary-light" {...row.getRowProps()}>
                        {
                          row.cells.map(cell => {
                            return <td className="px-6 py-4 whitespace-nowrap" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          })
                        }
                      </tr>
                    )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

function AdminSchoolsPage() {
  const { auth } = useStores();
  const searchKey = useRef<string>("");
  const searchRef = useRef(null);
  const schoolData = useQuery({
    queryKey: [],
    queryFn: () => auth.getSchools(searchKey.current).then(res => res.data),
  });
    
  const columns = React.useMemo(
      () => [
      {
          Header: "Scuole",
          columns: [
          {
            Header: "Modifica",
            accessor: "id",
            Cell: ({ cell }) => (
              <Link reloadDocument to={
                  `/admin/dashboard/schools/${cell.row.values.id}`}>
                <FaEdit />
              </Link>
            )
          },
          {
              Header: 'Nome',
              accessor: 'name',
          },
          {
            Header: 'Codice meccanografico',
            accessor: 'code',
          },
          {
            Header: "Tipo",
            accessor: "license",
            Cell: props => {
              return props.value == 1 ? "Pinguin" : "Miri"
            }
          },
          {
            Header: "Utenti",
            accessor: "users",
          },
          {
            Header: "Newsletter?",
            accessor: "newsletter_consent",
            Cell: props => {
              return props.newsletter_consent ? 'Sì' : 'No'
            }
          },
        ],
      },
      ],
      []
  );

  const search = event => {
    if (event.target.value && event.target.value.length < 4)
      return;

    searchKey.current = event.target.value;
    schoolData.refetch();
  }

  if (schoolData == null || schoolData.isLoading || schoolData.data == null)
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  return (
    <Container>
      <div className="flex flex-col gap-6 justify-start items-start">
        <div className='flex flex-row mt-4 space-x-2'>
          <Link reloadDocument className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" to="/admin/dashboard/schools/new">Aggiungi</Link>
          <input onChange={search} ref={searchRef} placeholder="Cerca (min 4 caratteri)" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          <CSVLink className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mr-4" data={schoolData.data}>Esporta</CSVLink>
        </div>
        <Table columns={columns} data={schoolData?.data} />
      </div>
    </Container>
  )
}

export default AdminSchoolsPage
