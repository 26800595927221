import { useStores } from '../../stores/store';
import { Link, useNavigate } from 'react-router-dom';
import { halve } from "../../utils/utils";
import { FaArrowLeft } from 'react-icons/fa';
import { config } from "../../config";
import { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';

function SubjectSelectionPage(props) {
	const { auth } = useStores();
	let navigate = useNavigate();
	let schoolType = auth.getLocalSchoolType();
	let subjects = auth.getLocalSchool().subjects.filter((subject) => subject.type == schoolType);
	let school = auth.getLocalSchool();
    const touchEnabled = useRef<boolean>(false);
	const [helpPopupShown, setHelpPopupShown] = useState<boolean>(false);

	useEffect(() => {
		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)
	}, [])

	const goToClass = subject => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;
		
		auth.saveLocalSubject(subject);
		navigate('/user/class-selection');
	}

	return (
		<div className="grid h-screen place-items-center w-full">
			<Popup open={helpPopupShown}
				position="right center"
			>
				<div className="flex flex-col items-center gap-6 p-6">
					<div>Disattivazione sensori Ctrl+Shift+P</div>
					<div>Calibrazione automatica Ctrl+Shift+C</div>
					<div>Torna indietro di una pagina Alt + freccia sinistra</div>
					<div>Chiusura applicazione Miri Alt + F4</div>
					<button className="bg-primary p-4 mx-2 rounded-lg text-white text-md text-center" onMouseDown={() => setHelpPopupShown(!helpPopupShown)} onTouchStart={() => config.isMiri() ? setHelpPopupShown(!helpPopupShown) : null}>Chiudi</button>
				</div>
			</Popup>

			<video className="absolute -z-10 w-full h-full object-fill" autoPlay loop>
				{ config.isMiri() ?
						<source src="/video/background_miri.mp4" type="video/mp4" />
					:
						<source src="/video/background_pinguin.mp4" type="video/mp4" />
				}
			</video>
			<div className="flex flex-col items-center justify-center">
				<div className="bg-primary rounded-t-2xl p-2 text-white text-3xl w-3/4 text-center">{ auth.getLocalSchool()?.name }</div>
				<div className="bg-white border-2 border-primary rounded-t-lg rounded-bl-lg p-2 shadow-2xl">
					<form className="flex items-center">
						{ subjects.length > 4 ? (
							<>
								<div className="flex flex-col items-center justify-start mb-8 mr-8 ml-8">
									{ halve(subjects, true).map((subject) => (
										<button onMouseDown={() => goToClass(subject)} onTouchStart={() => config.isMiri() ? goToClass(subject) : null} key={subject.id} style={{ backgroundColor: subject.color }} className="text-white text-4xl rounded-lg p-8 m-8 w-full whitespace-nowrap mb-1">{subject.name}</button>
									))}
								</div>
								<div className="flex flex-col w-1/2 items-center justify-start mb-8 mr-8 ml-8">
									{ halve(subjects, false).map((subject) => (
										<button onMouseDown={() => goToClass(subject)} key={subject.id} onTouchStart={() => config.isMiri() ? goToClass(subject) : null} style={{ backgroundColor: subject.color }} className="text-white text-4xl rounded-lg p-8 m-8 w-full whitespace-nowrap mb-1">{subject.name}</button>
									))}
								</div>
							</>) 
						: 
							<div className='flex flex-col items-center justify-start mb-8 mr-8 ml-8'>
								{subjects.map((subject) => (
									<button onMouseDown={() => goToClass(subject)} onTouchStart={() => config.isMiri() ? goToClass(subject) : null} key={subject.id} style={{ backgroundColor: subject.color }} className="text-white text-4xl rounded-lg p-8 m-8 w-full whitespace-nowrap mb-1">{subject.name}</button>
								))}
							</div>
						}
					</form>
				</div>
				<div className="flex justify-between items-start gap-1 w-full">
				{
					(school.is_primary_present && school.is_childhood_present) ?
						<div onMouseDown={() => navigate("/user/school-type-selection")} onTouchStart={() => config.isMiri() ? navigate("/user/school-type-selection") : null} className='bg-primary p-8 rounded-lg text-white text-4xl text-center ml-4 mt-4'>
							<FaArrowLeft />
						</div>
					:
						<div onMouseDown={() => navigate("/")} onTouchStart={() => config.isMiri() ? navigate("/") : null} className='bg-primary p-8 rounded-lg text-white text-4xl text-center'>
							LOGOUT
						</div>
				}
					<div className="flex justify-end items-start gap-1 w-full">
						{ config.isMiri() &&
							<button onMouseDown={() => setHelpPopupShown(!helpPopupShown)} onTouchStart={() => config.isMiri() ? setHelpPopupShown(!helpPopupShown) : null} className='bg-primary p-4 mx-2 rounded-b-lg text-white text-2xl text-center'>
								HELP
							</button>
						}
						<Link to="/user/subject-selection" className='bg-primary p-4 rounded-b-lg text-white text-2xl text-center'>
							CREDITS
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubjectSelectionPage;
